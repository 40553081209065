
<template>
  <div class="col-md-7 col-sm-7 mt-10 boxmain" v-if="is_loading">
    <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                    {{$t('vehicle.name')}}
                </h2>

              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12col-md-12 block-1">
                <article class="art_text table-responsive">
                  <div class="table-responsive">
                    <table class="table table-bordered" id="tableInvoice">
                      <thead>
                        <tr>
                          <th><Skeleton/></th>
                          <th><Skeleton/></th>
                          <th><Skeleton/></th>
                          <th><Skeleton/></th>
                          <th><Skeleton/></th>
                        <th><Skeleton/></th>
                         <th><Skeleton/></th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v->
                          <td><strong>
                             <Skeleton/>
                          </strong></td>
                          <td>
                            <Skeleton/>
                          </td>
                          <td>  <Skeleton/></td>
                          <td> <Skeleton/></td>
                          <td> <Skeleton/></td>
                          <td>
                             <Skeleton/>
                          </td>
                        </tr>
                       

                      </tbody>

                    </table>
                  </div>
                </article>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
               

              </div>
            </div>
          </div>

    </SkeletonTheme>

   </div>



   <div class="col-md-7 col-sm-7 mt-10 boxmain" v-else>
    <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                   {{$t('vehicle.name')}}
                </h2>

              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12col-md-12 block-1">
                <article class="art_text table-responsive">
                   <form method="post" @submit="mySearch" id="my_search" class='contact-form'>
                      <div class="row">
                      
                        <div class="col-sm-6 form-group ">
                          <input
                            type="text"
                            name="name"
                            class="form-control"
                           
                            autocomplete="off"
                            
                            :placeholder="$t('vehicle.name')" 
                          
                          />

                          <button class="btn btn-sm btn-warning mt-3" type="submit">
                            <i class="fa fa-search-plus"></i> {{$t('general.search_vehicle')}}
                          </button>
                        </div>
                        <div class="col-sm-6 form-group  mb-3">


                              <select v-if="location!=null" name="location" :placeholder="$t('vehicle.location')"   class="form-control" >
                                       <option  value="">   {{$t('vehicle.location')}}  </option>
                                       <option v-for="(v,k) in location" :key="k" :value="v.id">{{v.name}}</option>
                                    </select>

                        
                        </div>
                         
                      </div>
                    </form>
                  <div class="table-responsive">

                    <table class="table table-bordered" id="tableInvoice">
                      <thead>
                        <tr>
                          <th>
                            
                             {{$t('vehicle.code')}}
                          </th>
                          <th>
                             {{$t('vehicle.name')}}
                          </th>

                           <th>
                               {{$t('general.business')}}

                          </th>


                          <th>
                               {{$t('vehicle.location')}}

                          </th>
                          
                          <th> {{$t('vehicle.km')}}</th>
                          <th> {{$t('vehicle.timer')}}</th>
                          <th> {{$t('vehicle.price')}}</th>
                          <th> {{$t('vehicle.route')}}</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-for="(b,k) in paginationslice(my_data, 20, page)" :key="k">
                          <td><strong>
                              {{b.code}}
                          </strong></td>
                        <td>
                              {{b.name}}
                          </td>

                          <td>


                           
                              <a v-for="(b1,bk1) in ViewBusiness(b.multichoice,business)" :key="bk1"  data-toggle="modal" :data-target="'#my_model_'+b1.id">
                                 - {{b1.name}} <br />
                              </a>
                         
                        <div  v-if="b.multichoice!=null"  >
                         <div v-for="(b2,bk2) in b.multichoice.split(',')" :key="bk2"    class="modal fade bd-example-modal-lg content_area" :id="'my_model_'+b2" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                              <div class="modal-dialog modal-xs">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5 class="modal-title">
                                         {{$t('vehicle.name')}}: {{b.name}}
                                      </h5>
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div class="modal-body popupposition">
                                      <p>
                                        {{Detail_Business(b2).name}}
                                      </p>
                                      
                                     
                                       <div class="statusposition">
                                        <i class="fas fa-arrow-right"></i> 
                                             {{$t('business.address')}}: {{Detail_Business(b2).address}}
                                      </div>

                                       <div class="statusposition">
                                        <i class="fas fa-arrow-right"></i> 
                                            Email: {{Detail_Business(b2).email}}
                                      </div>

                                       <div class="statusposition">
                                        <i class="fas fa-arrow-right"></i> 
                                             {{$t('business.phone')}}:  {{Detail_Business(b2).phone}}
                                      </div>

                                       <div class="statusposition">
                                        <i class="fas fa-arrow-right"></i> 
                                           Website:  {{Detail_Business(b2).website}}
                                      </div>




                                     
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                          {{$t('booth.close')}}
                                      </button>
                                    
                                    </div>        
                                  </div>
                              </div>
                            </div>
                          </div>


                        </td>

                          <td>
                           
                             {{b.codeLocationDes | ViewLocation(location)}}
                            
                        </td>


                          <td>{{b.km}}</td>


                          <td>{{b.timer}}</td>

                          <td>{{b.price}} - {{b.price1}}</td>

                          <td>{{b.route=='undefined'? '': b.route}}</td>


                        </tr>
                       

                      </tbody>

                    </table>
                  </div>
                   <nav class="pagination-nav pdt-30" v-if="my_data.length>20">
                  

                    <VuePagination
                    :page-count="Math.floor(my_data.length / 20)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'pagination-list text-center'"
                    :page-class="'page-numbers'"
                  >
                  </VuePagination>
                </nav>
                </article>
                 

              </div>
            </div>
           
          </div>

    </SkeletonTheme>

   </div>



</template>


<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
  import axios from 'axios';
export default {
  
  "name":"vehicle",
  data() {
    return {
      
      is_loading:true,
      myurl:window.my_url,
      my_data:null,
      main:null,
      page: 1,
      location:null,
      business:null
      
    };
  },

  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination
    
  },
  created(){
       let p1=     axios.get(window.my_api+"api/vehicle/get-vehicles",
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
              .then( (res)=>{
                  
                  if(res.status==200){

                        this.main=this.my_data=res.data.data.sort( (a,b)=> {
                            if(a.name  > b.name ){
                                return 1;
                            }
                            if(a.name  < b.name ){
                                return -1;
                            }
                            return 0;

                       });
                                            
                  }else {
                    console.log("ERROR",res);
                  }
                   

                });
          
          let p2= axios.get(window.my_api + "api/location/get-locations", {
                  headers: window.my_header
                })
                .then((res) => {
                  if (res.status == 200) {
                    this.location  = res.data.data;
                 
                  } else {
                    alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
                  }
          });

        let p3= axios.get(window.my_api + "api/business/get-businesss", {
                  headers: window.my_header
                })
                .then((res) => {
                  if (res.status == 200) {
                    this.business  = res.data.data;
                 
                  } else {
                    alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
                  }
          });

         Promise.all([p1,p2,p3]).then(()=>{
            this.is_loading=false;
         });
      
  },
  methods:{
   
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },
    changePage(e) {
      this.page = e;
    },
    mySearch:function(event){
        event.preventDefault();
        
         let myForm = document.getElementById("my_search");

       let form = new FormData(myForm);


      this.my_data=this.main.filter( (n)=>{
            if(form.get("name").length && form.get("location").length){
              return    n.name.toUpperCase().search(form.get("name").toUpperCase() ) > -1 && n.codeLocationDes.toUpperCase().search(form.get("location") ) > -1;
            }

            if(form.get("name").length){
              return    n.name.toUpperCase().search(form.get("name").toUpperCase() ) > -1;
            }
            if(form.get("location").length && n.codeLocationDes){
              return    n.codeLocationDes.search(form.get("location") ) > -1;
            }
               if(form.get("name").length==0 && form.get("location").length==0){
                      return   true;
                }
                return false;


      });

        return false;
    },
    Detail_Business(id){
        var result= this.business.filter( (n)=> n.id==id);
        if(result.length){
            return result[0];
        }
        return null;

    },
     ViewBusiness:function(id,business){
       if(id!=null){
         let value_id= id.split(",");

         let result=[];

         for (var i = value_id.length - 1; i >= 0; i--) {
           
            var r= business.filter( (n)=> n.id==value_id[i]);

             if(r.length){
                result.push(r[0]);
            }
         }

         return result;
        
       }
        
        return [];
    }
  },
   filters:{
    ViewLocation:function(id,location){
        var result= location.filter( (n)=> n.id==id);
        if(result.length){
            return result[0].name;
        }
        return "";


    },
    ViewBusine:function(id,business){
       if(id!=null){
         let value_id= id.split(",");

         let result=[];

         for (var i = value_id.length - 1; i >= 0; i--) {
           
            var r= business.filter( (n)=> n.id==value_id[i]);

             if(r.length){
                result.push(r[0].name);
            }
         }

         return result.join("<br />");
        
       }
        
        return "";
    }

  },
}
</script>
<style type="text/css">
  #tableInvoice td ,#tableInvoice tr td, #tableInvoice tr th{
    font-size: 11.5px;
  }
  #tableInvoice td a{
      color: #0199f3;
  }
</style>
